import qs from "querystring";
import { GraphQLClient } from "graphql-request";

import { getSdk } from "./sdk";

const getClient = (locale?: string, params?: any) => {
  return new GraphQLClient(
    `${
      process.env.NEXT_PUBLIC_PRIMARY_DOMAIN ||
      process.env.NEXT_PUBLIC_SHOPIFY_SHOP_ORIGIN
    }/api/2024-10/graphql.json${
      params && Object.keys(params)?.length ? `?${qs.stringify(params)}` : ""
    }`,
    {
      headers: {
        "X-Shopify-Storefront-Access-Token":
          process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        ...(locale ? { "Accept-Language": locale } : {}),
      },
      timeout: 10000,
    }
  );
};

export const shopifySdk = getSdk(getClient());

export const getShopifySdk = (locale?: string, params?: any) =>
  getSdk(getClient(locale, params));
