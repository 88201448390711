import { Flex, Icon, Close, Box, css } from "@storyofams/react-ui";
import { Button as LinkButton } from "@storyofams/react-ui";
import Link from "next/link";
import { Button } from "../Button";
import { Info, CheckmarkCircle, XCircle, NavUnderline } from "../Icons";
import { Text } from "../Text";

type ToastProps = {
  msg: string;
  persistant?: boolean;
  type: "info" | "success" | "error" | "alert";
  link?: {
    href: string;
    text: string;
  };
  onClose?: () => void;
};

const content = {
  info: {
    icon: Info,
    color: "grey800",
  },
  success: {
    icon: CheckmarkCircle,
    color: "success600",
  },
  error: {
    icon: XCircle,
    color: "error600",
  },
  alert: {
    icon: Info,
    color: "error600",
  },
};

export const Toast = ({ msg, type, persistant, link }: ToastProps) => (
  <Box backgroundColor="grey100">
    {link?.href ? (
      <Flex
        p={1.5}
        width="100%"
        borderRadius="xs"
        position="relative"
        alignItems="center"
      >
        <Icon
          fontSize={3}
          icon={content[type].icon}
          color={content[type].color}
        />

        <Text
          ml={2}
          fontSize={2}
          color="grey800"
          maxWidth="100%"
          lineHeight={1.5}
        >
          {msg.split(`${link.text}`)[0]}
          <LinkButton
            display="inline"
            cursor="pointer"
            to={link.href}
            css={css({
              ".underline": {
                position: "absolute",
                right: "-4px",
                height: 12,
                bottom: 0,
                width: "calc(100% + 8px)",
                transition: "width 0.32s ease-in-out",
                svg: {
                  width: "100%",
                  height: "100%",
                  color: "sand",
                  opacity: "0.7",
                },
              },
              "&&:hover": {
                ".underline": {
                  width: "0",
                },
              },
            })}
          >
            {link.text}
            <Icon className="underline" icon={NavUnderline} width="100%" />
          </LinkButton>
          {msg.split(`${link.text}`)[1]}
        </Text>
      </Flex>
    ) : (
      <Flex
        p={1.5}
        width="100%"
        borderRadius="xs"
        position="relative"
        alignItems="center"
      >
        <Icon
          fontSize={3}
          icon={content[type].icon}
          color={content[type].color}
        />

        <Text ml={1} fontSize={2} color="grey800" lineHeight={1.5}>
          {msg}
        </Text>
        {persistant && (
          <Button
            variant="unstyled"
            css={{ position: "absolute", top: "13px", right: "12px" }}
          >
            <Icon icon={Close} color="grey800" fontSize="8px" />
          </Button>
        )}
      </Flex>
    )}
  </Box>
);
